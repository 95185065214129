import React from "react"
import Layout from "../components/common/Layout"
import { getBackLink, getHeaderTitles } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { Heading, Text } from "~/components/common/Typography"
import { Box } from "reflexbox"
import Copyright from "~/components/common/Copyright"
import OutsideLink from "~/components/common/OutsideLink"

const Page = ({ myPage = 8, mySubpage }) => {
  return (
    <Layout
      footer={{
        backLink: `/`,
        nextLink: `/introduction-why-it-matters/`,
        nextText: `NEXT: WHY IT MATTERS`,
      }}
      header={{ ...getHeaderTitles(myPage, mySubpage), hideProgress: true }}
    >
      <InfoboxDetailed headerText={null}>
        <Box textAlign={"center"} maxWidth={"mediumContent"} mx={"auto"}>
          <Heading
            uppercase
            fontSize={["20px", "40px"]}
            lineHeight={["33px", "60px"]}
            background="yellow"
            fontFamily={"AdineuePROBlackWeb"}
          >
            Keep Girls in Sport: A Digital Coaching Curriculum
          </Heading>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBlackWeb"}
            color={"black"}
            mt={[6]}
            mb={4}
          >
            ABOUT ADIDAS BREAKING BARRIERS
          </Text>

          <Text mt={0}>
            adidas believes that through sport, we have the power to change
            lives. In 2018, adidas launched Breaking Barriers, a global brand
            initiative to inspire and enable the next generation of female
            athletes, creators and leaders and by removing barriers in sport.
            She Breaks Barriers has three areas of focus – to provide better
            access to sport for women and girls, remove gender stereotypes, and
            to help provide visibility for female athletes at all levels and
            ages.
          </Text>

          <Text>
            One significant cause of girls' dropout rate is a lack of positive
            coaches and role models. With this, we set out to co-create an
            easily-available and interactive digital curriculum that offers
            training specifically for coaching girls. Let's work together to
            level the playing field for girls and women in sport!
          </Text>

          <Text>
            To learn more about this initiative and what adidas has done to help
            break down barriers for girls please visit
            <br />
            <OutsideLink
              link={"https://www.adidas.com/shebreaksbarriers"}
              color={"seafoam"}
            >
              www.adidas.com/shebreaksbarriers
            </OutsideLink>
          </Text>

          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBlackWeb"}
            color={"black"}
            mt={["24px", "24px", "40px"]}
            mb={4}
          >
            ABOUT UP2US SPORTS
          </Text>

          <Text mt={0}>
            Up2Us Sports is the nationwide leader in engaging, training and
            supporting sports coaches to serve as mentors and role models to
            youth living in some of America’s most underserved communities. We
            understand the important role highly-trained coaches play in
            inspiring girls to reach their potential through the power of
            sports. This curriculum builds on Up2Us Sports’ 12-year history of
            creating innovative youth-development coach training. If you are
            interested in learning more about Up2Us Sports training resources
            and coaching opportunities, visit the links below:
          </Text>

          <Text>
            To learn more about Up2Us Sports' She Changes the Game initiative,
            visit
            <br />
            <OutsideLink
              link={"https://www.up2us.org/she-changes-the-game"}
              color={"seafoam"}
            >
              https://www.up2us.org/she-changes-the-game
            </OutsideLink>
          </Text>

          <Text>
            To learn more about the Up2Us Sports Coach Program or to become a
            coach yourself, visit
            <br />
            <OutsideLink
              link={"https://www.up2us.org/about-coach"}
              color={"seafoam"}
            >
              https://www.up2us.org/about-coach
            </OutsideLink>
          </Text>

          <Text>
            To find out more about Up2Us Sports Training, visit
            <br />
            <OutsideLink
              link={"https://www.up2us.org/up2us-training"}
              color={"seafoam"}
            >
              https://www.up2us.org/up2us-training
            </OutsideLink>
          </Text>
        </Box>
      </InfoboxDetailed>

      <Copyright />
    </Layout>
  )
}

export default withProgress(Page, 8, 0, false)
